import React from 'react';
import ReactDOM from 'react-dom';
import Routes from './routes/Routes';
//import Footer from './pages/Footer'


ReactDOM.render(
  <React.StrictMode>
    <Routes />

    {/* <Footer /> */}
  </React.StrictMode>,
  document.getElementById('root')
);



